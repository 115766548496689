<template>
  <div class="tag-home">
    <el-card style="margin: 10px 0px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" size="mini" @click="addDialogVisible = true">+添加标签</el-button>
        </el-col>
      </el-row>
      <el-table size="mini" :data="tagList" border style="margin-top: 10px">
        <!-- 添加展开列 -->
        <!-- <el-table-column type="expand"></el-table-column> -->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column label="标签名称" prop="tagName"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="showEditDialog(scope.row.id)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="remove(scope.row.id)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加标签对话框 -->
    <el-dialog title="添加标签" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <el-form :model="addForm" ref="addFormRef" size="mini" label-width="100px">
        <el-form-item label="标签名称" prop="tagName">
          <el-input v-model="addForm.tagName" placeholder="请输入标签名称"  type="text"  maxlength="15" show-word-limit ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addArticleTag()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改标签的对话框 -->
    <el-dialog title="修改标签" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed">
      <!-- 内容主体 -->
      <el-form :model="editForm" ref="editFormRef" size="mini" label-width="70px">
        <el-form-item label="标签名称" prop="tagName">
          <el-input v-model="editForm.tagName" type="text"  maxlength="15" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editArticleTag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagList: [],
      addDialogVisible: false, // 添加菜单对话框
      addForm: {},
      editDialogVisible: false, // 编辑菜单对话框
      editForm: {},
      
    };
  },
  computed:{
    username(){
      return this.$store.state.permission.user.username;
    } 
  },
  created() {
    this.getArticleTag();
  },
  methods: {
    // 标签列表
    async getArticleTag() {
      const { data: res } = await this.$api.getArticleTag();
      this.tagList = res.data;
    },
    // 添加标签
    async addArticleTag() {
      this.addForm.username = this.username
      const { data: res } = await this.$api.addArticleTag(this.addForm);
      this.addDialogVisible = false;
      this.getArticleTag();
    },
    addDialogClosed() {
      //对话框关闭之后，重置表单
      this.$refs.addFormRef.resetFields();
    },
    // 显示编辑标签信息
    async showEditDialog(id) {
      const { data: res } = await this.$api.getArticleTag({id:id});
      this.editForm = res.data[0];
      this.editDialogVisible = true;
    },
    // 编辑标签
    async editArticleTag() {
      const { data: res } = await this.$api.editArticleTag(
        this.editForm.id,
        this.editForm
      );
      // 隐藏添加对话框
      this.editDialogVisible = false;
      this.getArticleTag();
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields();
    },
    // 删除标签
    async remove(id) {
      const result = await this.$confirm("是否删除该标签?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const { data: res } = await this.$api.deleteArticleTag(id);
      this.getArticleTag();
    }
  }
};
</script>

<style scoped>
.tag-home{
  height: 100%;
  width: 1500px;
}
</style>